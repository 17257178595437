// This file is autogenerated. Do not change it manually.
// If new react-root is added, please run `pnpm generate-react-roots`

export const getReactComponent = async (path: string) => {
  switch (path) {
    case "src/components/Chip/components/StatusChip":
      return await import('../../../msc_modules/src/components/Chip/components/StatusChip');
    case "src/components/Header":
      return await import('../../../msc_modules/src/components/Header');
    case "src/components/Icon":
      return await import('../../../msc_modules/src/components/Icon');
    case "src/components/IncomeLevels":
      return await import('../../../msc_modules/src/components/IncomeLevels');
    case "src/components/Toasts":
      return await import('../../../msc_modules/src/components/Toasts');
    case "src/pages/AchievementLeaders/components/CurrentContest":
      return await import('../../../msc_modules/src/pages/AchievementLeaders/components/CurrentContest');
    case "src/pages/AchievementLeaders/components/FindOutMore":
      return await import('../../../msc_modules/src/pages/AchievementLeaders/components/FindOutMore');
    case "src/pages/AchievementLeaders/components/SeasonalContestFindOutMore":
      return await import('../../../msc_modules/src/pages/AchievementLeaders/components/SeasonalContestFindOutMore');
    case "src/pages/Awards/components/AwardsFirstArrivalPage":
      return await import('../../../msc_modules/src/pages/Awards/components/AwardsFirstArrivalPage');
    case "src/pages/Dashboard/widgets/DailyTips":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/DailyTips');
    case "src/pages/Dashboard/widgets/EroticTeaser":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/EroticTeaser');
    case "src/pages/Dashboard/widgets/ExclusiveModel":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/ExclusiveModel');
    case "src/pages/Dashboard/widgets/PeriodStatistics":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/PeriodStatistics');
    case "src/pages/Dashboard/widgets/SelectionStatus":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/SelectionStatus');
    case "src/pages/Dashboard/widgets/SelectionTaskList":
      return await import('../../../msc_modules/src/pages/Dashboard/widgets/SelectionTaskList');
    case "src/pages/EarnMore/ActionRequests":
      return await import('../../../msc_modules/src/pages/EarnMore/ActionRequests');
    case "src/pages/EarnMore/ClubElite":
      return await import('../../../msc_modules/src/pages/EarnMore/ClubElite');
    case "src/pages/EarnMore/FanClub":
      return await import('../../../msc_modules/src/pages/EarnMore/FanClub');
    case "src/pages/EarnMore/HotDeal":
      return await import('../../../msc_modules/src/pages/EarnMore/HotDeal');
    case "src/pages/EarnMore/InteractiveToy":
      return await import('../../../msc_modules/src/pages/EarnMore/InteractiveToy');
    case "src/pages/EarnMore/PrivateShows":
      return await import('../../../msc_modules/src/pages/EarnMore/PrivateShows');
    case "src/pages/EarnMore/RewardsClub":
      return await import('../../../msc_modules/src/pages/EarnMore/RewardsClub');
    case "src/pages/EarnMore/SoldierAid":
      return await import('../../../msc_modules/src/pages/EarnMore/SoldierAid');
    case "src/pages/EarnMore/TipsAndSurprises":
      return await import('../../../msc_modules/src/pages/EarnMore/TipsAndSurprises');
    case "src/pages/EarnMore/TopMember":
      return await import('../../../msc_modules/src/pages/EarnMore/TopMember');
    case "src/pages/EarnMore/VipShow":
      return await import('../../../msc_modules/src/pages/EarnMore/VipShow');
    case "src/pages/Error/Error500":
      return await import('../../../msc_modules/src/pages/Error/Error500');
    case "src/pages/Help/OnlineSupportChat/components/SupportChat":
      return await import('../../../msc_modules/src/pages/Help/OnlineSupportChat/components/SupportChat');
    case "src/pages/LandingPage/CertifiedStudios":
      return await import('../../../msc_modules/src/pages/LandingPage/CertifiedStudios');
    case "src/pages/LandingPage/Footer.container":
      return await import('../../../msc_modules/src/pages/LandingPage/Footer.container');
    case "src/pages/Layouts/components/ProfileInfo":
      return await import('../../../msc_modules/src/pages/Layouts/components/ProfileInfo');
    case "src/pages/Login/LoginForm":
      return await import('../../../msc_modules/src/pages/Login/LoginForm');
    case "src/pages/Members/FanClub":
      return await import('../../../msc_modules/src/pages/Members/FanClub');
    case "src/pages/Profile/Expertise/PersonalIntroductionTabs":
      return await import('../../../msc_modules/src/pages/Profile/Expertise/PersonalIntroductionTabs');
    case "src/pages/Profile/Privacy/Tabs":
      return await import('../../../msc_modules/src/pages/Profile/Privacy/Tabs');
    case "src/pages/Profile/Price":
      return await import('../../../msc_modules/src/pages/Profile/Price');
    case "src/pages/Profile/ProfilePicture":
      return await import('../../../msc_modules/src/pages/Profile/ProfilePicture');
    case "src/pages/Shared/CookieConsent":
      return await import('../../../msc_modules/src/pages/Shared/CookieConsent');
    case "src/pages/Shared/ServiceAgreementConsentModal":
      return await import('../../../msc_modules/src/pages/Shared/ServiceAgreementConsentModal');
    case "src/pages/AchievementLeaders":
      return await import('../../../msc_modules/src/pages/AchievementLeaders');
    case "src/pages/AutomatedMessages":
      return await import('../../../msc_modules/src/pages/AutomatedMessages');
    case "src/pages/Awards":
      return await import('../../../msc_modules/src/pages/Awards');
    case "src/pages/CertifiedStudioDashboard":
      return await import('../../../msc_modules/src/pages/CertifiedStudioDashboard');
    case "src/pages/ChangePassword":
      return await import('../../../msc_modules/src/pages/ChangePassword');
    case "src/pages/Dashboard":
      return await import('../../../msc_modules/src/pages/Dashboard');
    case "src/pages/EarnMore/BeMyFan":
      return await import('../../../msc_modules/src/pages/EarnMore/BeMyFan');
    case "src/pages/EarnMore/EroticTeasers":
      return await import('../../../msc_modules/src/pages/EarnMore/EroticTeasers');
    case "src/pages/EarnMore/Messenger":
      return await import('../../../msc_modules/src/pages/EarnMore/Messenger');
    case "src/pages/EarnMore/MobileLive":
      return await import('../../../msc_modules/src/pages/EarnMore/MobileLive');
    case "src/pages/EarnMore/Stories":
      return await import('../../../msc_modules/src/pages/EarnMore/Stories');
    case "src/pages/EarnMore/VideoCalls":
      return await import('../../../msc_modules/src/pages/EarnMore/VideoCalls');
    case "src/pages/EarnMore/components/DownloadApp/DownloadApp":
      return await import('../../../msc_modules/src/pages/EarnMore/components/DownloadApp/DownloadApp');
    case "src/pages/ForgotPassword":
      return await import('../../../msc_modules/src/pages/ForgotPassword');
    case "src/pages/Login":
      return await import('../../../msc_modules/src/pages/Login');
    case "src/pages/Messenger":
      return await import('../../../msc_modules/src/pages/Messenger');
    case "src/pages/MusicLibrary":
      return await import('../../../msc_modules/src/pages/MusicLibrary');
    case "src/pages/Shorts":
      return await import('../../../msc_modules/src/pages/Shorts');
    case "src/pages/StatisticsDashboard":
      return await import('../../../msc_modules/src/pages/StatisticsDashboard');
    default:
      throw new Error(`Unknown component ${path} Maybe src/component/App/generate-react-roots.ts is not run after a new .react-root is added?`);
  }
};