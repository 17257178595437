/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */

import $ from 'jquery';
import Hammer from 'hammerjs';

import Ext from '../../lib/vendor/ExtCore';
import Chaos from '../../lib/chaos/Chaos';
import ChaosObject from '../../lib/chaos/Object';
import Config from '../../lib/chaos/Config';
import Util from '../../lib/chaos/Util';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import IsMobile from '../../lib/chaos/IsMobile';
import CONST from '../../lib/constant/Constants';
import PH from '../../lib/constant/Phrame';

import GlobalProgressIndicator from '../ProgressIndicator/GlobalProgressIndicator';
import Ajax from '../Ajax/Ajax';
import DataSender from '../Ajax/DataSender';
import OverlayController from './OverlayController';

/**
 * Overlay : overlay creator
 */

/**
 * Overlay component event constant
 * @type {string}
 */
export default class Overlay extends ChaosObject {
	static UPDATE_OPEN_OVERLAY_ELEMENTS = 'update-open-overlay-elements';
	static CLOSE_OVERLAY = 'close-overlay';
	static BEFORE_CLOSE_OVERLAY = 'before-close-overlay';
	static AFTER_CLOSE_OVERLAY = 'after-close-overlay';
	static OVERLAY_UNBIND_CMPS = 'unbind-overlay-cmps';
	static OVERLAY_READY = 'overlay-ready';

	get properties() {
		return { ...super.properties,
			/** @var {Obj}    template                  Az ajaxbol kapott html tartalom  */
			_template : undefined,
			/** @var {Obj}    windowScrollPos           Az aktualis scroll pozicioit tartalmazo object (x,y)  */
			_windowScrollPos : undefined,
			/** @var {Obj}    overLayObj                Az overlay  */
			_overlayObj : undefined,
			/** @var {Obj}                              Az overlay ID erteke  */
			_overlayElementId : 'overlayContainer',
			/** @var {Obj}                              Az overlay eleme  */
			_overlayElement : undefined,
			/** @var {String} _overlayContentElementCls  Az overlay ID erteke  */
			_overlayContentElementCls : 'overlayContent',
			/** @var {Obj} _overlayContentElement       Az overlay eleme  */
			_overlayContentElements : undefined,
			/** @var {String} _mainContainerElementId   Az OLDAL fo kontenerenek ID-ja  */
			_mainContainerElementId : 'main_container',
			/** @var {Obj} _mainContainerElement        Az OLDAL fo kontenerenek eleme  */
			_mainContainerElement : undefined,
			/*                                          Overlayeket megjelenito gombok gyujtoje */
			_openOverlayBtnList : undefined,
			/** @var {Obj}                              Az overlay  */
			_overlayButtons : undefined,
			/** @var {String}                           Az overlay mogotti kontent class-a, ha overlay van felette */
			overlayedMainContentCls : 'overlayed',
			/*                                          Overlayeket megjelenito gombok ostalya */
			overlayBtnSel : '.overlayBtn',
			/* */
			overlaySel : '.overlayBlock',
			/* */
			_loadOverlayBtnSel : '.loadOverlayBtn[href]',
			/* */
			_refreshAfterClosure : false,
			/* */
			errorContainerSel : '.errorContainer',
			/*                                            Az overlay tartalom fokontener classa */
			_innerContainerClass : '.overlayInnerBox',
			/*                                            Az overlay tartalom fokontener eleme */
			_innerContainerEl : undefined,
			/*                                            Az overlay tartalom regisztracios lepesek fejlecenek ID-ja */
			_createPersonStepsBlockId : '#personCreationStepsBlock',
			/*                                            Az overlay tartalom regisztracios lepesek fejlecenek eleme */
			_createPersonStepsBlockEl : undefined,
			/*                                            The currently clicked element, item or prev/next button */
			_clickedElement : undefined,
			/*                                            Current id selector from data attribute */
			_currentIdDataSel : 'data-overlay-id',
			/*                                            Value of data-overlay-id attribute */
			_currentId : undefined,
			/*                                            Pagination object generated by backend */
			_paginationObject : undefined,
			/*                                            Data attribute selector which holds the Config key
                                                  name for the pagination object */
			_paginationDataConfigSel : 'data-pagination-object',
			/** @var {String}                           Cls to prevent click event on overlay (opaque area)... (in practice: no close on overlay click) */
			preventOverlayClickCls : 'preventOverlayClick',
			/** @var {String}                           ID of overlay right arrow */
			overlayArrowRightId : 'galleryRightStep',
			/** @var {String}                           ID of overlay left arrow */
			overlayArrowLeftId : 'galleryLeftStep',
			/** @var {String}                           selector of step elements */
			stepSel : '.step',
			/** @var {String}                           selector of active step element */
			stepActiveSel : '.active',
			/** @var {String}                           class of active step element */
			stepActiveCls : 'active',
			/** @var {String}                          selector of step counter spans (actual step + last step) */
			stepCounterSel : '.step_count_number',
			/** @var {String}                           Selector of the close button of the overlay */
			closeBtnSel : '.closeButton',

			popupParams : {},

			/** @var {Obj}                              Custom post data for the request */
			_postData : undefined,

			/** @var {String}                           Selector for attached POST data (optional) */
			_postDataSel : 'data-post'

		};
	}
	init(el, config) {
		//Global events
		Broadcaster.on(
			Overlay.UPDATE_OPEN_OVERLAY_ELEMENTS,
			this.updateOpenOverlayBtnListEventHandler,
			this
		);
		//az overlayt megjelenito gombok osztalya
		this._openOverlayBtnList = this.element.select(this.overlayBtnSel);
		this._overlayElement = Ext.get(this._overlayElementId);
		this.selectOverlayElements();
		this._mainContainerElement = Ext.get(this._mainContainerElementId);

		// Implement Object keys feature for older browsers
		Object.keys = Object.keys || function(o) {
			var result = [];
			for (var name in o) {
				if (o.hasOwnProperty(name)) {
					result.push(name);
				}
			}
			return result;
		};

		super.init(el, config);
	}

	/**
	 * Get last element of the CompositeElement
	 * @param {Object} composite Composite element
	 * @param {Number} nthLast Nth last element. optional. default 1.
	 * @return {Object} Ext element - last element of the composite
	 */
	getLastEl(composite, nthLast) {
		nthLast = nthLast || 1;

		if (!(composite instanceof Ext.CompositeElementLite)) {
			return false;
		}
		var last = composite.elements.length - nthLast;
		return Ext.get(composite.elements[last]);
	}

	/**
	 * Getter method of the _overlayContentElement. It returns with the active (last)
	 *
	 * @returns {Ext.Element}
	 */
	getOverlayContentElement() {
		return this.getLastEl(this._overlayContentElements);
	}

	/**
	 * Selects overlay elements from the dom to a variable
	 */
	selectOverlayElements() {
		this._overlayContentElements = Ext.select(this._overlayContentElementCls.dot());
	}

	/**
	 *
	 */
	updateOpenOverlayBtnListEventHandler() {
		this._leftArrowEl = Ext.get(this.overlayArrowLeftId);
		this._rightArrowEl = Ext.get(this.overlayArrowRightId);

		this.setNextPrev();

		this._openOverlayBtnList = Ext.select(this.overlayBtnSel);
	}

	/**
	 *
	 */
	findNewOverlayButtons() {
		this._leftArrowEl = Ext.get(this.overlayArrowLeftId);
		this._rightArrowEl = Ext.get(this.overlayArrowRightId);

		this.setNextPrev();

		this._overlayButtons = this._overlayElement.select(this._loadOverlayBtnSel);

		this._overlayButtons.un('click', this.newOverlayButtonsEventHandler, this);
		this._overlayButtons.on('click', this.newOverlayButtonsEventHandler, this);
	}

	/**
	 *
	 * @param ev
	 * @param target
	 */
	newOverlayButtonsEventHandler(ev, target) {
		ev.preventDefault();
		ev.stopPropagation();

		// Store the link element
		var anchor = this._clickedElement = target.tagName.toLowerCase === 'a' ?
			target :
			Ext.get(target).findParent('a');

		this._postData = anchor.getAttribute(this._postDataSel);

		this.getOverlay(anchor.href);
	}

	/**
	 * Forward url handler.
	 * @param url
	 * @param params Az overlay lekerese soran kuldott paramok
	 */
	getOverlay(url, params) {
		var paramsObj;
		// Ha meg van adva rendes params object akkor hozzatesszuk az isOverlay: 1-et
		if (typeof params === 'object') {
			paramsObj = params;
			paramsObj.isOverlay = 1;
		}
		// Ha nincs akkor csak siman az isOverlay-t kuldjuk paramkent
		else {
			paramsObj = { isOverlay : 1 };
		}

		var method = CONST.GET;

		// If there is custom post data
		if (this._postData) {
			paramsObj = Ext.apply(paramsObj, JSON.parse(this._postData));
			method = CONST.POST;
			this._postData = false;
		}

		Ajax.request({
			type    	: CONST.TYPE_JSON,
			url    		: url,
			params   : paramsObj,
			scope   	: this,
			success 	: this.getOverlaySuccessHandler,
			error   	: this.getOverlayErrorHandler,
			failure 	: this.getOverlayFailureHandler,
			method  	: method,
			synchron : true
		});
	}

	/**
	 * popupok altalanos kezeleset teszi lehetove
	 *
	 * @param mixed   input            az elem, amire kattintottunk VAGY az url amit nyitnank
	 * @param bool     destroyPopup   torolje-e a dom fabol bezaraskor az adott elemet
	 * @param object   loadParams     ajaxos hivashoz extra parameterek
	 * @param object   popupParams    popup egyeb adatai
	 *                                - closeBtnName:   a popup bezarjo gomjainak azonositoja, tobb eseten vesszovel
	 *                                                  elvalasztva
	 *                                - noEval:         true|false|undefined   eval-al futtasuk a callback fgv-t
	 * @param object   overLayObj     az overlay object
	 *
	 * @return void
	 */
	popupHandler(
		input,
		destroyPopup,
		loadParams,
		popupParams,
		overLayObj
	) {
		var loadAjaxUrl, paramObj, hashMark = undefined;
		var method = CONST.GET;

		if (input instanceof Ext.Element) {
			loadAjaxUrl	= input.dom.getAttribute('href');
		}
		else {
			loadAjaxUrl	= input;
		}
		if (loadAjaxUrl.indexOf('#') !== -1) {
			hashMark = loadAjaxUrl.split('#')[1];
			loadAjaxUrl = loadAjaxUrl.split('#')[0];
		}
		if (loadAjaxUrl === '') {
			/* webpack-strip-block:removed */
			return false;
		}
		// Ha vannak loadParam-ok akkor bovitjuk vele a paramObj-t
		if (loadParams) {
			paramObj = loadParams;
			paramObj.isOverlay = 1;
		}
		else {
			paramObj = { isOverlay : 1 };
		}
		// If there is custom post data
		if (this._postData) {
			paramObj = Ext.apply(paramObj, JSON.parse(this._postData));
			method = CONST.POST;
			// `url` variable is not defined anywhere (even in the global scope)
			// url += '/?isOverlay=1';
			this._postData = false;
		}

		this.destroyPopup = destroyPopup;
		this.loadParams = paramObj;
		this.popupParams = popupParams;
		this._overlayObj = overLayObj;
		this._hashMark = hashMark;

		$('body').protipHideInside();

		Ajax.request({
			type     : CONST.TYPE_JSON,
			url      : loadAjaxUrl,
			params   : paramObj,
			scope    : this,
			success  : this.popupHandlerSuccessHandler,
			error    : this.popupHandlerErrorHandler,
			failure  : this.popupHandlerFailureHandler,
			method   : method,
			synchron : true
		});
	}

	/**
	 *
	 * @param data
	 */
	async createPopup(data) {
		// Overlay Opened
		Config.set('isOverlayOpened', true);
		// Collect settings from data attributes
		this.collectDataSettings();

		// a kapott js objektumokat taroljuk el Chaosban
		for (var key in data.jsObject) {
			if (data.jsObject.hasOwnProperty(key)) {
				Config.set(key, data.jsObject[key]);
			}
		}

		var last = this._overlayContentElements.elements.length - 1,
			lastOverlayContentElement = this._overlayContentElements.item(last),
			mode = this._clickedElement && Ext.get(this._clickedElement).data('overlayMode');

		// If mode append, and the last overlaycontent element is not empty
		if (mode && mode === 'append' && lastOverlayContentElement.dom.textContent.trim() !== '') {
			var newOverlayElement = Ext.DomHelper.insertAfter(lastOverlayContentElement, {
				tag : 'div',
				cls : 'overlayContent'
			});
			lastOverlayContentElement = Ext.get(newOverlayElement);
			this._overlayContentElements = Ext.select(this._overlayContentElementCls.dot());
		}
		lastOverlayContentElement.html(data.overlay);

		//Jelzi, hogy bezaras utan ujra kell e tolteni az oldalt
		var val = parseInt(data.refreshAfterClosure, 10);
		if (this._overlayContentElements.elements.length === 1) {
			this._refreshAfterClosure = isNaN(val) ? false : val === 1;
		}

		/*
		 * Pozicionalasok
		 */

		// mentsuk ki a window jelenlegi helyzetet
		this._windowScrollPos = {
			x : window.scrollX || document.documentElement.scrollLeft,
			y : window.scrollY || document.documentElement.scrollTop
		};

		// Mobile hide address bar
		window.scrollTo(0, 1);

		//Allitsd be es fixald a main container poziciojat
		this._mainContainerElement.addClass(this.overlayedMainContentCls);

		//Ha a scrollpos 0 akkor hagyd meg az eredeti top erteket (mar megnyitott ablak ujratoltesekor)
		if (this._windowScrollPos.y) {
			this.setElementStyle(
				'',
				this,
				{
					element : this._mainContainerElement,
					top     : -this._windowScrollPos.y + 'px'
				}
			);
		}
		this.setElementStyle(
			'',
			this,
			{
				element : this._overlayElement,
				display : 'block'
			}
		);
		this._overlayObj = this._overlayObj || Ext.get('overlay');
		//A kattinthato reteg a bezarashoz
		this.setElementStyle(
			'',
			this,
			{
				element : this._overlayObj,
				width   : '100%',
				display : 'block'
			}
		);

		// Hide protips in main container
		$('#main_container .protip').each(function(i, el) {
			if ($._protipClassInstance) {
				$._protipClassInstance.getItemInstance($(el)).el.protip.addClass(PH.cls.hide);
			}
		});

		this.positionInnerContent();
		//windowra resize esemeny rakotese
		Ext.fly(window).on('resize', this.resizeOverlayContainer, this);

		// Gallery-leftright buttons
		this._leftArrowEl = Ext.get(this.overlayArrowLeftId);
		this._rightArrowEl = Ext.get(this.overlayArrowRightId);

		if (this._leftArrowEl || this._rightArrowEl) {
			Ext.fly(window).un('keyup', this._galleryKeyUpAndSwipeHandler, this)
				.on('keyup', this._galleryKeyUpAndSwipeHandler, this);
			// Instantiate swiping on every device/screen size
			if (!this._hammer && IsMobile.any()) {
				this._hammer = new Hammer(this.getOverlayContentElement().dom)
					.on('swipeleft swiperight', this._galleryKeyUpAndSwipeHandler.bind(this));
			}
		}

		//close gombra es az overlay-re az esemenyek rakotese
		var closeBtnSel = this.popupParams.closeBtnName || this.closeBtnSel,
			closeBtn = this._overlayElement.select(closeBtnSel);

		if (closeBtn.getCount() > 0) {
			closeBtn
				.un('click', this.closePopupEventHandler, this)
				.on('click',
					this.closePopupEventHandler,
					this,
					{
						closeBtnName : this.popupParams.closeBtnName,
						popupParams  : this.popupParams,
						destroyPopup : this.destroyPopup
					}
				);
			// Always remove close events
			this._overlayContentElements.un('click', this.onContentClickHandler, this);
			this._overlayObj.un('click', this.closePopupEventHandler, this);
			Ext.fly(window).un('keydown', this.closePopupEventHandler, this);
			this._overlayContentElements
				.on('click',
					this.onContentClickHandler,
					this,
					{
						closeBtnName      : this.popupParams.closeBtnName,
						popupParams       : this.popupParams,
						destroyPopup      : this.destroyPopup,
						overlayObjClicked : true
					}
				);
			this._overlayObj
				.on('click',
					this.closePopupEventHandler,
					this,
					{
						closeBtnName      : this.popupParams.closeBtnName,
						popupParams       : this.popupParams,
						destroyPopup      : this.destroyPopup,
						overlayObjClicked : true
					}
				);
			Ext.fly(window)
				.on('keydown',
					this.closePopupEventHandler,
					this,
					{
						closeBtnName : this.popupParams.closeBtnName,
						popupParams  : this.popupParams,
						destroyPopup : this.destroyPopup
					}
				);
		}

		/* ha szeretnenk , hogy bezaraskor tiszta url-el frissuljon az oldal */
		if (this.popupParams.refreshClose) {
			this._overlayElement.select(this.popupParams.closeBtnName).item(0)
				.un('click')
				.on('click',
					function() {
						var oldUrl = window.location.href.split('?')[0];
						window.location.href = Util.getUrl(oldUrl);
						return false;
					});
		}

		//Felgorgetek az oldal tetejere ha nincs mark
		if (this._hashMark !== undefined) {
			window.location.hash = this._hashMark;
			window.location = window.location.href;
		}
		else {
			window.scrollTo(0, 0);
		}

		//Focus allitas
		this._overlayElement.focus();

		var submitEl = this._overlayElement.select('input[type=submit], button[type=submit]').item(0);

		if (data.postUrl) {
			this._dataSender = new DataSender(
				this._overlayElement,
				{
					overlayComponent : this,
					submitBtnEl      : submitEl,
					postUrl          : data.postUrl
				}
			);
		}

		var overlayId = '';
		var overlayEl = this.getLastEl(this._overlayElement.select(this.overlaySel));
		var promise = Promise.resolve();
		if (overlayEl) {
			// peldanyositsuk a megfelelo js osztalyokat az overlay azonsoitoja alapjan
			overlayId = overlayEl.id;
			this._overlayController = this._getOverlayControllerCmp();
			promise = this._overlayController.pageController(
				overlayId,
				this._clickedElement,
				data,
				this,
				closeBtn
			);
		}
		await promise;
		//Ellatjuk oket ismet a megfelelo esemenykezelokkel
		Chaos.fireEvent(GlobalProgressIndicator.GLOBALEVENT_HIDE_INDICATOR);
		Broadcaster.fireEvent(Overlay.OVERLAY_READY, { overlayId, data });
		this.findNewOverlayButtons();
		setTimeout(() => $(window).resize());
		//Frissitjuk az overlaygombok listajat
		this._openOverlayBtnList = Ext.select(this.overlayBtnSel);
	}

	/**
	 * If there is no namespace, we use this method to get the overlaycontroller.
	 * @private
	 */
	_getOverlayControllerCmp() {
		return new OverlayController();
	}

	/**
	 * Az overlay tartalomra kattintas esemenykezeloje
	 * @param ev
	 * @param target
	 * @param data
	 */
	onContentClickHandler(ev, target, data) {
		//Csak akkor hivjuk meg a bezaro fuggvenyt, ha az id megfelelo (az atlatszo vagy ures bg)
		if (ev.target.classList.contains(this._overlayContentElementCls)) {
			this.closePopupEventHandler(ev, target, data);
		}
	}

	/**
	 *
	 * @param ev
	 */
	_galleryKeyUpAndSwipeHandler(ev) {
		// ev.keyCode = input from keyboard, ev.type = swipe input
		var input = ev.keyCode || ev.type;

		switch (input) {
			// Left
			case 37:
			case 'swiperight':
				if (this._leftArrowEl && this._leftArrowEl.isVisible()) {
					this._clickedElement = this._leftArrowEl.dom;
					this.handleDirectionKey();
				}
				break;
			// Right
			case 39:
			case 'swipeleft':
				if (this._rightArrowEl && this._rightArrowEl.isVisible()) {
					this._clickedElement = this._rightArrowEl.dom;
					this.handleDirectionKey();
				}
				break;
		}
	}

	/**
	 * Left or Right key press handler.
	 *
	 * @return void
	 */
	handleDirectionKey() {
		this._clickedElement.click();
	}

	/**
	 * Popupok bezarasat vegzo fgv.
	 *
	 * @param ev
	 * @param target
	 * @param data
	 * @param {bool} silent   If true, we won't notify the world about it is closed.
	 *                        It's useful when a pushState router handles the overlay.
	 */
	closePopupEventHandler(ev, target, data, silent) {
		var overlayBlockEl = this.getLastEl(this._overlayElement.select(this.overlaySel)),
			last;

		if (this._hammer) {
			this._hammer.off('swipeleft swiperight', this._galleryKeyUpAndSwipeHandler.bind(this));
			this._hammer.destroy();
			this._hammer = undefined;
		}

		//csak ESC billentyure zarjunk be.
		if (ev && ev.type === 'keydown' && ev.keyCode !== 27) {
			return;
		}

		if (overlayBlockEl && overlayBlockEl.hasClass(this.preventOverlayClickCls) && data && data.overlayObjClicked) {
			return;
		}

		if (ev) {
			ev.preventDefault();
			ev.stopPropagation();
		}

		// give a chance anyone who needs to cancel closing event
		if (!this.fireEvent(Overlay.BEFORE_CLOSE_OVERLAY, ev, this)) {
			return;
		}

		if (this._overlayContentElements.elements.length > 1) {
			last = this.getLastEl(this._overlayContentElements);
			last.remove();

			this.selectOverlayElements();
			// Fire an event about closing the overlay
			this.fireEvent(Overlay.CLOSE_OVERLAY, this);
			Broadcaster.fireEvent(Overlay.OVERLAY_UNBIND_CMPS);
			return;
		}

		// Show protips in main container
		$('#main_container .protip').each(function(i, el) {
			if ($._protipClassInstance) {
				$._protipClassInstance.getItemInstance($(el)).el.protip.removeClass(PH.cls.hide);
			}
		});

		//Kimentem a top erteket ahova majd vissza kell pozicionalni
		var windowTop = this._mainContainerElement.dom.offsetTop;

		//elrejtjuk az elemeket
		this.changeDisplay(this._overlayElement, 'none');
		this.changeDisplay(this._overlayObj, 'none');

		// If overlay opened with a hash (and content element has this in a data attr), then remove it.
		var hash = overlayBlockEl && overlayBlockEl.data('hash');

		if (hash) {
			var newHash = window.location.hash.replace(hash, '');
			window.location.hash = newHash;
		}

		// Overlay closed
		Config.set('isOverlayOpened', false);
		$('body').removeClass('no-overscroll');

		//leszedjuk az esemenykezeloket
		Ext.fly(window).un('resize', this.resizeOverlayContainer, this)
			.un('keydown', this.closePopupEventHandler, this)
			.un('keyup', this._galleryKeyUpAndSwipeHandler, this);

		if (this._hammer) {
			this._hammer.destroy();
		}
		if (this._overlayObj) {
			this._overlayObj.un('click', this.closePopupEventHandler, this);
		}

		//A main container visszaallitasa eredetire
		this._mainContainerElement.removeClass(this.overlayedMainContentCls);
		this._mainContainerElement.setTop(0);

		// Fire an event about closing the overlay
		!silent && this.fireEvent(Overlay.CLOSE_OVERLAY, this);

		// allitsuk vissza a poziciot eredetire
		window.scrollTo(0, Math.abs(windowTop));

		//toroljuk a DOM-bol ha kell
		if (data && data.destroyPopup) {
			last = this._overlayContentElements.elements.length - 1;
			var el = Ext.get(this._overlayContentElements.item(last).dom.children[0]);
			if (el) {
				el.remove();
			}
		}

		//Bezaras utan frissiti az oldalt
		if (this._refreshAfterClosure) {
			window.location.reload();
		}
		// Ha nem lesz oldalfrissites akkor unbindeljuk es megsemmisitjuk a datasendert, hogy ne kavarjon be
		else {
			if (this._dataSender) {
				this._dataSender.unbind();
			}
			delete this._dataSender;
			Broadcaster.fireEvent(Overlay.OVERLAY_UNBIND_CMPS);
		}

		// Plain window resize
		$(window).trigger('resize');
	}

	/**
	 * Styleokat beallito seged fgv.
	 *
	 * @param ev
	 * @param target
	 * @param data
	 *
	 * @return void
	 */
	setElementStyle(ev, target, data) {
		var element = data.element;
		for (var key in data) {
			if (key !== 'element') {
				element.setStyle(key, data[key]);
			}
		}
	}

	/**
	 * Overlayt meretezo seged fgv.
	 *
	 * @return void
	 */
	resizeOverlayContainer() {
		this.positionInnerContent();
	}

	/**
	 *
	 * @param response
	 */
	getOverlaySuccessHandler(response) {
		response = JSON.parse(response.responseText).data;
		if (response.done) {
			window.location.href = response.redirectUrl;
		}
		else if (response.hasOwnProperty('forwardUrl')) {
			this.getOverlay(response.forwardUrl);
		}
		else {
			//Elkeszitjuk a popupot
			this.createPopup(response);
		}
	}

	/**
	 *
	 * @param response
	 */
	getOverlayErrorHandler(response) {
		/* webpack-strip-block:removed */
	}

	/**
	 *
	 * @param response
	 */
	getOverlayFailureHandler(response) {
		/* webpack-strip-block:removed */
	}

	/**
	 * Success event handler of the popupHandler ajax request.
	 *
	 * @param {object} response ajax response
	 */
	popupHandlerSuccessHandler(response) {
		response = JSON.parse(response.responseText).data;
		if (response.done) {
			window.location.href = response.redirectUrl;
		}
		else if (response.hasOwnProperty('forwardUrl')) {
			this.getOverlay(response.forwardUrl);
		}
		else {
			//Elkeszitjuk a popupot
			this.createPopup(response);
		}
	}

	/**
	 *
	 * @param response
	 */
	popupHandlerErrorHandler(response) {
		/* webpack-strip-block:removed */
	}

	/**
	 *
	 * @param response
	 */
	popupHandlerFailureHandler(response) {
		/* webpack-strip-block:removed */
	}

	/**
	 * Display-t allito metodus
	 *
	 * @param el	string		Az elem amit modositani szeretnenk
	 * @param value string		Az ertek amit adni akarunk a displaynek
	 *
	 * @TODO: This is frontend magic :O
	 *
	 * @return void
	 */
	changeDisplay(el, value) {
		if (el) {
			Ext.fly(el).setStyle('display', value);
		}
	}

	/**
	 * Opens an overlay programatically
	 *
	 * @var {String} url The URL that we want to open
	 */
	openOverlay(url, loadParams = '') {
		this._clickedElement = loadParams.targetEl;

		this.popupHandler(
			url,
			true,
			loadParams,
			{
				closeBtnName : this.closeBtnSel
			},
			Ext.get('overlay')
		);
	}

	/**
	 * Collect every settings from the anchor data attributes
	 */
	collectDataSettings() {
		if (typeof this._clickedElement === 'undefined') {
			/* webpack-strip-block:removed */
			return;
		}
	}

	/**
	 *
	 * @param ev
	 * @param target
	 */
	onOverlayBtnClick(ev, target) {
		ev.preventDefault();
		ev.stopPropagation();

		// FYI I'm using <anchor> tag as well to avoid some unwanted mobile behaviors
		var isTargetAnchor = target.tagName.toLowerCase() === 'a' || target.tagName.toLowerCase() === 'anchor';
		var anchor = isTargetAnchor ? target : Ext.get(target).findParent('a'),
			anchorEl = Ext.get(anchor);

		if (anchor.hasAttribute('disabled') || anchorEl.hasClass('disabled')) return;

		this._postData = anchor.getAttribute(this._postDataSel);

		this._clickedElement = anchor;

		// Collect settings from data attributes
		this.collectDataSettings();

		this.popupHandler(
			anchorEl,
			true,
			false,
			{
				closeBtnName : this.closeBtnSel
			},
			Ext.get('overlay')
		);

		// Overlay Opened
		Config.set('isOverlayOpened', true);
		$('body').addClass('no-overscroll');
	}

	positionInnerContent() {
		var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		// Rakd be a tartalmat kozepre. Ezt azert kell js-el fixen, hogy az auto scrollbar felugrasakor se valtozzon
		// a tartalom helye. (window resize meg el van kapva)
		var last = this._overlayContentElements.elements.length - 1;
		this._innerContainerEl = this._overlayContentElements.item(last).select(this._innerContainerClass).item(0);

		if (!this._overlayContentElements) {
			return;
		}

		if (this._innerContainerEl && this._innerContainerEl.hasClass('ph-full-width')) {
			var viewportW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			this._innerContainerEl.setWidth(viewportW);
		}

		//Ha van regisztracios fejlec is, azt is pozicionald kozepre
		this._createPersonStepsBlockEl = this.element.select(this._createPersonStepsBlockId).item(0);
		if (this._createPersonStepsBlockEl) {
			var createPersonStepsBlockList = this._createPersonStepsBlockEl.select('ul').item(0);

			this.setElementStyle(
				'',
				this,
				{
					element : createPersonStepsBlockList,
					left    : windowWidth / 2 + 'px'
				}
			);
		}
	}

	/**
	 * Set the next/prev button from pagination object
	 * */
	setNextPrev() {
		if (!this._clickedElement) {
			return;
		}
		// Get the current ID if exists, it will be used for next/prev generation
		this._currentId = this._clickedElement.getAttribute(this._currentIdDataSel);

		// Get pagination object if data-pagination-object attribute is set
		this._paginationObject = this._paginationObject
			? this._paginationObject
			: this._clickedElement.getAttribute(this._paginationDataConfigSel)
				? Config.get(this._clickedElement.getAttribute(this._paginationDataConfigSel))
				: false;

		// Only set if there was a pagination object
		if (this._paginationObject) {
			var keys = Object.keys(this._paginationObject),
				index = keys.indexOf(this._currentId),
				hasNext = typeof keys[index + 1] !== 'undefined' ? keys[index + 1] : false,
				hasPrev = typeof keys[index - 1] !== 'undefined' ? keys[index - 1] : false;

			if (hasPrev) {
				var prevObj = this._paginationObject[hasPrev];
				if (this._leftArrowEl) {
					this._leftArrowEl.dom.href = prevObj;
					this._leftArrowEl.dom.setAttribute(this._currentIdDataSel, hasPrev);
					this._leftArrowEl.setStyle('display', 'block');
				}
			}
			else if (this._leftArrowEl) {
				this._leftArrowEl.setStyle('display', 'none');
			}

			if (hasNext) {
				var nextObj = this._paginationObject[hasNext];
				if (this._rightArrowEl) {
					this._rightArrowEl.dom.href = nextObj;
					this._rightArrowEl.dom.setAttribute(this._currentIdDataSel, hasNext);
					this._rightArrowEl.setStyle('display', 'block');
				}
			}
			else if (this._rightArrowEl) {
				this._rightArrowEl.setStyle('display', 'none');
			}
		}
	}

	/**
	 *
	 */
	bind() {
		Ext.getBody().on('click', this.onOverlayBtnClick, this, {
			delegate : this.overlayBtnSel
		});

		super.bind();
	}

	/**
	 *
	 */
	unbind() {
		this.autoUnbind();
	}
}